import React from "react"

import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import FavoriteIcon from "@material-ui/icons/Favorite"
import { red, blue } from "@material-ui/core/colors"
import FacebookIcon from "@material-ui/icons/Facebook"
import InstagramIcon from "@material-ui/icons/Instagram"
import TwitterIcon from "@material-ui/icons/Twitter"
import IconButton from "@material-ui/core/IconButton"

const useStyles = makeStyles(theme => ({
  footer: {
    backgroundColor: "rgba(0,0,0,0.05)",
    padding: theme.spacing(1)
  },
  wrapIcon: {
    verticalAlign: "middle",
    display: "inline-flex"
  },
  link: {
    textDecoration: "none"
  }
}))

function Copyright() {
  const classes = useStyles()

  return (
    <>
      <Typography variant="body2" color="textPrimary" align="center">
        NextBook {new Date().getFullYear()}
        <Typography variant="body2" align="center">
          Made with{" "}
          <FavoriteIcon
            className={classes.wrapIcon}
            style={{ color: red[500] }}
          />{" "}
          by{" "}
          <a
            className={classes.link}
            href="https://twitter.com/ab7_ab7"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: blue[700] }}
          >
            {"@ab7_ab7"}
          </a>{" "}
          and{" "}
          <a
            className={classes.link}
            href="https://aayushbhutani.com/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: blue[700] }}
          >
            {"@aayushbhutani26"}
          </a>
        </Typography>
        <Typography variant="caption" color="textSecondary" align="center">
          Nextbook is a participant in the Amazon Services LLC Associates
          Program which is an advertising program designed to provide a means
          for sites to earn advertising fees by linking to www.amazon.com.
        </Typography>
        <br />
        <a
          href="https://www.facebook.com/nextbooktoread/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <IconButton>
            <FacebookIcon />
          </IconButton>
        </a>
        <a
          href="https://www.instagram.com/nextbookrecommender/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <IconButton>
            <InstagramIcon />
          </IconButton>
        </a>
        <a
          href="https://twitter.com/nextbooktoread"
          target="_blank"
          rel="noopener noreferrer"
        >
          <IconButton>
            <TwitterIcon />
          </IconButton>
        </a>{" "}
        <br />
        <a
          href="https://peachplugin.com/?ref=nextbooktoread"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={require("../images/peachBadgeRecWhite.png")}
            alt="peachBadge"
            height="60"
            width="200"
          />
        </a>{" "}
      </Typography>
    </>
  )
}

function Footer() {
  const classes = useStyles()

  return (
    <footer className={classes.footer}>
      <Copyright />
    </footer>
  )
}

export default Footer
