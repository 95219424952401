import { useStaticQuery, graphql } from "gatsby"

export const useBooks = () => {
  const data = useStaticQuery(
    graphql`
      query Books {
        postgres {
          books: allBooks {
            nodes {
              id
              title
              authors
              publishedDate
              description
              pageCount
              averageRating
              ratingsCount
              categories
              imagelink
              textSnippet
              recommendedBy
              alias
            }
          }
        }
      }
    `
  )
  return data.postgres.books.nodes
}
