import React from "react"
import { navigate } from "gatsby"

import { isMobileOnly } from "react-device-detect"

import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import { fade, makeStyles } from "@material-ui/core/styles"
import IconButton from "@material-ui/core/IconButton"
import Link from "@material-ui/core/Link"
import CssBaseline from "@material-ui/core/CssBaseline"
import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks"
import PersonIcon from "@material-ui/icons/Person"

import Search from "./search"

const useStyles = makeStyles(theme => ({
  root: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  search: {
    display: "flex",
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    padding: "8px",
    flex: 1,
    maxWidth: 1000,
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      padding: "8px",
      width: "auto",
      flex: 1
    }
  }
}))

export default function Header() {
  const classes = useStyles()

  const handleBooksClick = () => {
    navigate("/books")
  }

  const handlePersonsClick = () => {
    navigate("/persons")
  }

  return (
    <>
      <CssBaseline />
      <AppBar
        position="fixed"
        color="default"
        style={{ backgroundColor: "white" }}
      >
        <Container>
          <Toolbar
            style={{ display: "flex", justifyContent: "center", padding: 0 }}
          >
            <Link
              href="/"
              color="textPrimary"
              align="left"
              style={{ padding: 8 }}
            >
              <img
                alt="main icon"
                width="40px"
                src={require("../images/logo_blue.png")}
                style={{ display: "inline-flex", verticalAlign: "middle" }}
              />
            </Link>
            <div className={classes.search}>
              <Search />
            </div>
            {isMobileOnly ? (
              <IconButton onClick={handleBooksClick}>
                <LibraryBooksIcon color={"default"} />
              </IconButton>
            ) : (
              <Button
                style={{ textTransform: "none" }}
                variant={"filled"}
                color="primary"
                onClick={handleBooksClick}
              >
                Books
              </Button>
            )}
            {isMobileOnly ? (
              <IconButton onClick={handlePersonsClick}>
                <PersonIcon color={"default"} />
              </IconButton>
            ) : (
              <Button
                style={{ textTransform: "none" }}
                variant={"filled"}
                color="primary"
                onClick={handlePersonsClick}
              >
                People
              </Button>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    </>
  )
}
