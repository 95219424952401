import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

function SEO({ title, description, meta }) {
  return (
    <Helmet
      htmlAttributes={{
        lang: "en"
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: `Updated (November 2020): ` + description
        },
        {
          property: `og:title`,
          content: title
        },
        {
          property: `og:description`,
          content: `Updated (November 2020): ` + description
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:creator`,
          content: "@ab7_ab7"
        },
        {
          name: `twitter:title`,
          content: title
        },
        {
          name: `twitter:description`,
          content: description
        }
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  meta: [],
  title: `Books recommended by famous people`,
  description: `Search highly recommended books by famous
  people like Bill Gates, Barack Obama, Elon Musk and others who are
  entrepreneurs, actors, politicians, leaders and venture capitalists`
}

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object)
}

export default SEO
