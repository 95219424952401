import { useStaticQuery, graphql } from "gatsby"

export const usePersons = () => {
  const data = useStaticQuery(
    graphql`
      query People {
        postgres {
          persons: allPeople {
            nodes {
              id
              name
              description
              tags
              recommendations
              alias
            }
          }
        }
      }
    `
  )
  return data.postgres.persons.nodes
}
